.contact {
    position: relative;
    height:100vh;
    max-width: 1366px;
    display: flex;
    align-items: center;
    gap: 0px;
    background-image: url("../../Assets/images/stars.png");
    background-size: cover;

   


    .textContainer {
      align-self: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 50px;
      gap: 40px;
      white-space: nowrap;



      h1 {
        font-size: 80px;
        line-height: 88px;
        font-weight: 500;
        

      

      }

      span {
        font-weight: 900;
      }
    }

    .formContainer {
      flex: 1;
      position: relative;
      

     


      .phoneSvg {
        stroke: #d9974b;
        position: absolute;
        margin: auto;
        z-index: -1;
      }

      form {
        display: flex;
        width: 80%;
        margin-left: 20px;
        flex-direction: column;
        gap: 20px;
        color: black;

        

        input,
        textarea {
          padding: 20px;
          background-color: transparent;
          border: 1px solid white;
          color: white;
          border-radius: 5px;
          }

        button {
          padding: 20px;
          border: none;
          background-color: #fa9a2c;
          cursor: pointer;
          font-weight: 500;

         

        }
      }
    }
  }
  .social{
    display: flex;
    gap: 20px;

    img{
        width: 60px;
        height: 60px;
        object-fit: cover;
        margin-top: 5px;

       
    }



}
.toast {
  position: fixed;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  z-index: 1000;
  text-align: center;
}
.social {
    display: inline-block;
    margin-left: 14px;
}
.social a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social a::before {
    content: "";
    
    width: 52px;
    height: 52px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social a:hover::before {
    transform: scale(1);
}
.social-icon{
  display: flex;
  justify-content: center;
  width: 70%;
}
.social a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
@media(max-width:800px){
  .contact{
    flex-direction: column;
        margin-top: 0px;
        align-items: center;
        width: 100%;
        margin: 0px;
  }
  .contact .textContainer{
    font-size: 50px;
    line-height: 55px;
    white-space: nowrap;
    margin: 10px;
  }
  .formContainer{
    padding-right: 0px;
  }
  .social-icon{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0;
  }
  .phoneSvg{
   left: 0;
   transform: translateX(-10%);
  }
  form{
    width: 100%;
    opacity: 1;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  .contact input, textarea{
    width: 400px;
  }
 .contact button{
    width: 400px;
  }
}