.portfolio {
    position: relative;
    background:linear-gradient(180deg ,#1e0123 15%,#000000 );
   
 
    .pro{
      position: sticky;
      top: 30px;
      left: 0;
      padding-top: 50px;
      text-align: center;
      color: orange;
      font-size: 36px;
      background-color: #151515c1;
      z-index: 2;

 
        animation: fadeIn 3s;
        animation-direction: initial;
        transition: 3s;
     
      
      
  
      @include mobile {
        padding-top: calc(100vh - 100px);
        font-size: 24px;
      }
  
      .progressBar {
        height: 10px;
        background-color: rgb(255, 255, 255);
      }
    }
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        

    .wrapper{
      max-width: 1366px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      gap: 50px;
        
    .imagecontainer{
        flex: 1;
        height: 50%;
       
   
    }
      .textContainer{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;

        h2{
            font-size: 54px;
        }
        p{
            color: gray;
            font-size: 12px;
        }
        button{
            background-color: orange;
            border: none;
            border-radius: 10px;
            padding: 10px;
            width: 200px;
        }
      }
    }
    }
  
    
}
.img-contain{
        
    width: 100%;
    height: 100%;
    object-fit: cover;
   
  }
@keyframes fadeIn {
    0%{
        opacity: 0;

    }
    30%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
  }
  @media (max-width:800px) {
    .portfolio {
      & .container {
          & .wrapper {
              & .textContainer {
                  h2 {
                      font-size: 38px;
                  }
              }
              flex-direction: column;
              margin-top: 20px;
          }
          margin-top: 30px;
      }
  }
    
  }