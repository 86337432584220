.parallax{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: url(../../Assets/images/banner-bg.png);
    

   


.mountains{
    background-image: url("../../Assets/images/mountains.png");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;

}
.planets{
    background-image: url("../../Assets/images/planets.png");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;

}
.stars{
    background-image: url("../../Assets/images/stars.png");
   
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
z-index: 1;
}
}
.parallax-text{
    font-size: 80px;
    white-space: nowrap;
}
@media (max-width:1280px) {
    .parallax-text{
        font-size: 65px;
    }
}
@media (max-width:768px) {
    .parallax-text{
        font-size: 45px;
    }
}
@media (max-width:500px) {
    .parallax-text{
        font-size: 24px;
    }
}